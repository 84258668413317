import React from 'react'
import Img from 'gatsby-image'

const MobileBottomImages = ({ image1, image2 }) => (
  <div className='section is-hidden-desktop without-vertical-padding'>
    <div className='spacer' />
    <div className='columns is-tablet is-mobile'>
      <div className='column'>
        <Img fluid={image1.childImageSharp.fluid} alt='' />
      </div>
      <div className='column'>
        <Img fluid={image2.childImageSharp.fluid} alt='' />
      </div>
    </div>
  </div>
)

export default MobileBottomImages
