import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

const Header = ({ meta_title, meta_description, heading, breadcrumb }) => {
  return (
    <>
      <Helmet>
        <title>{meta_title}</title>
        <meta name='description' content={meta_description} />
      </Helmet>
      <section className='hero is-primary'>
        <div className='hero-body subpage'>
          <div className='container'>
            <div className='columns is-desktop'>
              <div className='column is-10'>
                <h2 className='is-size-2 anthracite-70-text capitalize page-heading'>
                  {heading}
                </h2>
              </div>
              <div className='column is-2 anthracite-70-text text-center'>
                <span className='breadcrumb'>{breadcrumb}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='yellow-border' />
    </>
  )
}

Header.propTypes = {
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  breadcrumb: PropTypes.string,
}

export default Header
