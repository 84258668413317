import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import Header from '../Header'
import MobileBottomImages from '../MobileBottomImages'

const ReferencesPageTemplate = ({
  meta_title,
  meta_description,
  references,
}) => (
  <StaticQuery
    query={graphql`
      query ReferencesImgQuery {
        image1: file(
          relativePath: {
            eq: "image-referenzen_01.png"
          }
        ) {
          childImageSharp {
            fluid(maxWidth: 500, maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image2: file(
          relativePath: {
            eq: "image-referenzen_02.png"
          }
        ) {
          childImageSharp {
            fluid(maxWidth: 500, maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <div>
        <Header
          meta_title={meta_title}
          meta_description={meta_description}
          heading={references.heading}
          breadcrumb='HOME > REFERENZEN'
        />
        <section className='section section--gradient without-vertical-padding'>
          <div className='container anthracite-text'>
            <div className='section without-vertical-padding'>
              <div className='columns is-desktop'>
                <div className='column is-three-quarters-tablet is-half-desktop'>
                  <div className='content'>
                    <h3 className='light'>{references.description}</h3>
                    <div>
                      <ul>
                        {references.items_left.map(item => (
                          <li key={item.title}>{item.title}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='column is-hidden-touch'>
                  <Img fluid={data.image1.childImageSharp.fluid} alt='' />
                </div>
              </div>
            </div>
            <div className='section without-vertical-padding'>
              <div className='columns is-desktop'>
                <div className='column is-hidden-touch'>
                  <Img fluid={data.image2.childImageSharp.fluid} alt='' />
                </div>
                <div className='column is-three-quarters-tablet is-half-desktop'>
                  <div className='content'>
                    <p className='is-size-5 light'>{references.description_right}</p>
                    <div>
                      <ul>
                        {references.items_right.map(item => (
                          <li key={item.title}>{item.title}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <MobileBottomImages image1={data.image1} image2={data.image2} />
          </div>
        </section>
      </div>
    )}
  />
)

ReferencesPageTemplate.propTypes = {
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  references: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    items_left: PropTypes.array,
    items_right: PropTypes.array,
    description_right: PropTypes.string,
  }),
}

export default ReferencesPageTemplate
